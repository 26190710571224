import React from 'react';
import {
    ITrackingActivity,
} from 'graphql/sales/shipment/trackingInterfaces';
import { Activity } from './activity';
import styles from '../../../style.css';

interface IActivitiesProps {
    activities: ITrackingActivity[];
}

export const Activities: React.FC<IActivitiesProps> = (
    {
        activities,
    },
) => {
    if (!activities.length) {
        return null;
    }

    return (
        <div className={styles.trackingActivities}>
            {activities.map(
                (activity: ITrackingActivity) => (
                    <Activity activity={activity} />
                ),
            )}
        </div>
    );
};
