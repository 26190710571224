import React, { useState, useEffect } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import {
    IShipmentTracking,
    ITrackingShipmentItem,
} from 'graphql/sales/shipment/trackingInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    TypeBtnMouseOrKeyboardClickEvent,
    SalesActionButton,
} from 'ui/component/sales-components/sales-action-button';
import { BuyAgainAction } from 'ui/component/orders-list/order/items/item/buy-again-action';
import { Link } from 'ui/component/custom/link';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import { ShipmentImages } from '../shipment-images';
import { Packages } from './packages';
import styles from '../style.css';

interface ITrackingInfoWindowProps {
    config: ISalesLayoutConfig;
    isLoggedIn: boolean|null;
    shipmentTracking: IShipmentTracking|undefined;
    orderStatus: string;
}

export const TrackingInfoWindow: React.FC<ITrackingInfoWindowProps> = (
    {
        config,
        isLoggedIn,
        shipmentTracking,
        orderStatus,
    },
) => {
    // TODO: implement update delivery instructions logic - then remove hardcoded variable to show the button
    const showUpdateDeliveryInstructionsBtn = false;
    const [showReturnsBtn, setShowReturnsBtn] = useState<boolean>(false);
    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const { salesConfig } = config;
    const { customerOrdersUrl } = salesConfig;

    const checkHasAvailableRmaItems = (
        trackingShipmentItems: ITrackingShipmentItem[],
    ): boolean => {
        const shipmentItemsEligibleForReturn: ITrackingShipmentItem[] = trackingShipmentItems.filter(
            (shipmentItem: ITrackingShipmentItem) => {
                const {
                    order_item: orderItem,
                } = shipmentItem;
                const {
                    eligible_for_return: isEligible,
                    rma_available_qty: rmaAvailableQty,
                } = orderItem;
                return isEligible && rmaAvailableQty > 0;
            },
        );
        return shipmentItemsEligibleForReturn.length > 0;
    };

    useEffect(() => {
        const trackingShipmentItems: ITrackingShipmentItem[] = shipmentTracking?.trackingShipment?.items ?? [];
        const isRmaAvailable: boolean = checkHasAvailableRmaItems(trackingShipmentItems);
        setShowReturnsBtn(isRmaAvailable);
    }, [shipmentTracking]);

    if (!shipmentTracking) {
        return null;
    }

    const {
        tracking,
        trackingShipment,
        trackingOrder,
    } = shipmentTracking;
    const {
        title: carrierTitle,
        number,
        packages: trackingPackages,
    } = tracking;

    const {
        items: shipmentItems,
    } = trackingShipment;

    const {
        order_urls: orderUrls,
    } = trackingOrder;

    const {
        details_url: orderDetailsUrl,
        create_returns_url: createReturnsUrl,
    } = orderUrls;

    const buyOrderItems = shipmentItems.map(
        (shipmentItem: ITrackingShipmentItem) => {
            const {
                order_item: orderItem,
                quantity_shipped: qtyShipped,
            } = shipmentItem;
            const { parentBundleItem } = orderItem;
            return {
                ...(parentBundleItem || orderItem),
                quantity_ordered: parentBundleItem ? parentBundleItem.quantity_ordered : qtyShipped,
            };
        },
    );

    const updateDeliveryInstructions = (event?: TypeBtnMouseOrKeyboardClickEvent): void => {
        if (event) event.preventDefault();
        // TODO: Navigate to Update delivery Instructions
    };

    const returnOrReplaceItems = (event?: TypeBtnMouseOrKeyboardClickEvent): void => {
        if (event) event.preventDefault();
        navigateToUrl({
            navigate,
            url: createReturnsUrl,
        });
    };

    const descriptionStatus = orderStatus !== 'Complete' ? t('Shipped') : t('Delivered');

    return (
        <div className={styles.trackingInfoWindow}>
            <div className={classes(styles.info, styles.head)}>
                <div className={classes(styles.headRow, styles.header)}>
                    {trackingPackages && trackingPackages.length > 0 && (
                        <Packages
                            packages={trackingPackages}
                            orderStatus={orderStatus}
                        />
                    )}
                    {(!trackingPackages || !trackingPackages.length) && (
                        <div className={styles.descriptionStatus}>
                            <div className={styles.title}>
                                {descriptionStatus}
                            </div>
                        </div>
                    )}
                    {isLoggedIn && (
                        <div className={styles.allOrders}>
                            <Link
                                href={customerOrdersUrl}
                                label={t('See all orders')}
                                linkClassName={styles.trackingLink}
                                opacityOnHover
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={classes(styles.info, styles.content)}>
                <div className={classes(styles.contentRow, styles.top)}>
                    <div className={styles.trackingInfo}>
                        <div className={classes(styles.infoLine, styles.carrierTitle)}>
                            <div className={styles.title}>
                                {t('%1 by %2', descriptionStatus, carrierTitle)}
                            </div>
                        </div>
                        <div className={classes(styles.infoLine, styles.trackingId)}>
                            {t('Tracking ID: %1', number)}
                        </div>
                        <div className={classes(styles.infoLine, styles.seeAllUpdates)}>
                            <Link
                                href={(isLoggedIn ? customerOrdersUrl : orderDetailsUrl)}
                                label={t('See all updates')}
                                linkClassName={styles.trackingLink}
                                opacityOnHover
                            />
                        </div>
                    </div>
                    <div
                        className={classes(styles.productImages, {
                            [styles.singleImage]: shipmentItems.length === 1,
                            [styles.multipleImages]: shipmentItems.length > 1,
                        })}
                    >
                        <ShipmentImages
                            shipmentItems={shipmentItems}
                        />
                    </div>
                </div>
                <div className={classes(styles.contentRow, styles.bottom)}>
                    {showUpdateDeliveryInstructionsBtn && (
                        <SalesActionButton
                            label={t('Update Delivery Instructions')}
                            className={classes(styles.actionBtn, styles.deliveryInstructions)}
                            action={updateDeliveryInstructions}
                        />
                    )}
                    {showReturnsBtn && (
                        <SalesActionButton
                            label={t('Return or replace items')}
                            className={classes(styles.actionBtn, styles.returnOrReplace)}
                            action={returnOrReplaceItems}
                        />
                    )}
                    <BuyAgainAction
                        config={config}
                        orderItems={buyOrderItems}
                        btnLabel={t('Buy again')}
                        wrapperClassName={styles.buyAgainWrapper}
                        btnClassName={classes(styles.actionBtn, styles.buyAgain)}
                        showBtnImage={false}
                        messagesClassName={styles.buyAgainMessages}
                        showMessagesInPopup
                    />
                </div>
            </div>
        </div>
    );
};
