import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Tracking } from 'ui/component/shipment/tracking';
import {
    salesLayoutConfigVar,
    ISalesLayoutConfig,
} from '../layouts/sales-layout/sales-layout-state';
import { PageContent } from '../layouts/components/page-content';
import { InitializeTracking } from './tracking-state';
import { LoaderContext } from './tracking-loader';
import styles from './style.css';

export const TrackingContent: React.FC = () => {
    const [trackingLoading, setTrackingLoading] = useState(false);
    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    return (
        <LoaderContext.Provider value={{ trackingLoading, setTrackingLoading }}>
            <InitializeTracking>
                <PageContent
                    pageLoading={trackingLoading}
                    className={styles.trackingContentWrapper}
                    pageContentClassName={styles.trackingContent}
                >
                    <Tracking config={config} />
                </PageContent>
            </InitializeTracking>
        </LoaderContext.Provider>
    );
};
