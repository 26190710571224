import React, { useState, useEffect, useRef } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IMapProps {
    googleMapsLibraryUrl: string;
    address: string;
    className?: string;
    isInfoOpen: boolean;
    setIsInfoOpen: (isInfoOpen: boolean) => void;
    children: React.ReactNode;
}

export interface IPosition {
    lat: number;
    lng: number;
}

export const Map: React.FC<IMapProps> = (
    {
        googleMapsLibraryUrl,
        address,
        className,
        isInfoOpen,
        setIsInfoOpen,
        children,
    },
) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [position, setPosition] = useState<IPosition|null>(null);
    const [map, setMap] = useState<google.maps.Map|null>(null);
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement|null>(null);
    const [markerInfoWindow, setMarkerInfoWindow] = useState<google.maps.InfoWindow|null>(null);

    const t = usePhraseTranslater();

    const googleMapRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    const scriptId = 'google-map-script';

    const addScriptToHead = (): void => {
        const googleScript = document.getElementById(scriptId);
        if (!googleScript) {
            const script = document.createElement('script');
            script.src = googleMapsLibraryUrl;
            script.id = scriptId;
            script.async = true;
            script.defer = true;
            script.addEventListener('load', () => {
                setIsLoaded(true);
            });
            document.head.appendChild(script);
        } else {
            setIsLoaded(true);
        }
    };

    const initMap = async (): Promise<void> => {
        if (!googleMapRef?.current) {
            return;
        }

        const mapOptions = {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapId: 'DEMO_MAP_ID',
        };

        const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
        const initiatedMap = new Map(googleMapRef.current as HTMLElement, mapOptions);
        setMap(initiatedMap);
    };

    const initMarker = async (): Promise<void> => {
        if (!map || !position) {
            return;
        }

        const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
        const initiatedMarker = new AdvancedMarkerElement({
            map,
            position,
            title: 'Address Marker',
        });
        setMarker(initiatedMarker);
    };

    const resolveAddressPosition = (address: string) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status: string) => {
            if (status === 'OK' && results && results[0]) {
                const result = results[0];
                const { geometry } = result;
                const { location } = geometry;
                const { lat, lng } = location;
                const addressPosition: IPosition = {
                    lat: lat(),
                    lng: lng(),
                };
                setPosition(addressPosition);
            }
        });
    };

    const openInfoWindow = () => {
        if (!markerInfoWindow) {
            const initializedInfoWindow = new google.maps.InfoWindow({
                content: t('Delivery Address: %1', address),
            });
            setMarkerInfoWindow(initializedInfoWindow);
            initializedInfoWindow.open(map, marker);
            return;
        }

        markerInfoWindow.open(map, marker);
    };

    const resolveMap = () => {
        if (map && marker && position) {
            let mapCenter: IPosition = {
                ...position,
                lng: (position.lng - 0.015),
            };
            if (window.innerWidth < 876) {
                mapCenter = {
                    ...position,
                    lat: (position.lat + 0.2),
                    lng: (position.lng),
                };
            }

            map.setCenter(mapCenter);
            marker.addListener('click', openInfoWindow);
            openInfoWindow();
        }
    };

    const removeMarkerEventListener = (): void => {
        if (marker) {
            marker.removeEventListener('click', openInfoWindow, false);
        }
    };

    useEffect(() => {
        if (!isLoaded) {
            addScriptToHead();
        }

        /**
         * Regardless Google Map is loaded or not - we open info window
         */
        setIsInfoOpen(true);
    }, []);

    useEffect(() => {
        if (isLoaded) {
            initMap().then();
        }
    }, [isLoaded]);

    useEffect(() => {
        if (!map || !address || !address.length) {
            return;
        }

        resolveAddressPosition(address);
    }, [map]);

    useEffect(() => {
        if (!position) {
            return;
        }

        initMarker().then();
    }, [position]);

    useEffect(() => {
        if (marker) {
            resolveMap();
        }

        return () => removeMarkerEventListener();
    }, [marker]);

    return (
        <div className={classes(styles.googleMapWrapper, (className || ''))}>
            <div ref={googleMapRef} className={styles.googleMap} data-test="google-map-wrapper" />
            <div
                className={classes(styles.googleInfoWindow, {
                    [styles.open]: isInfoOpen,
                })}
                data-test="google-map-info-content"
            >
                {children}
            </div>
        </div>
    );
};
