import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import productPlaceholder from 'assets/images/product-placeholder.png';
import styles from './style.css';

interface IProductImageProps {
    className?: string;
    productImageUrl?: string;
    productName?: string;
    width?: number;
    height?: number;
    counter?: number;
    counterClassName?: string;
    children?: JSX.Element;
}

export const ProductImage: React.FC<IProductImageProps> = (
    {
        className,
        productImageUrl,
        productName,
        width,
        height,
        counter,
        counterClassName,
        children,
    },
) => {
    const getImageWidth = () => {
        if (width && width > 0) {
            return { width };
        }

        return {};
    };
    const getImageHeight = () => {
        if (height && height > 0) {
            return { height };
        }

        return {};
    };

    return (
        <>
            <div
                className={classes(styles.productImageWrap, (className || ''))}
                style={{
                    ...getImageWidth(),
                    ...getImageHeight(),
                }}
            >
                <img
                    className={styles.productImage}
                    src={productImageUrl || productPlaceholder}
                    alt={productName || 'Product Image'}
                    width={width || 'auto'}
                    height={height || 'auto'}
                />
                {counter && counter > 0 && (
                    <span className={classes(styles.counter, (counterClassName || ''))}>{counter}</span>
                )}
            </div>
            {children && (<>{children}</>)}
        </>
    );
};
