import { IOrderItem } from '../../../customer/customerOrdersInterfaces';
import {
    IShipmentTracking,
    ITrackingShipment,
    ITrackingShipmentItem,
} from '../trackingInterfaces';
import {
    getParentOrderItems,
    getUpdatedOrderItemWithParentBundle,
} from '../../../customer/util/transform-customer-orders';

const updateReturnOrderItemsWithBundleItem = (
    parentOrderItems: IOrderItem[],
    trackingShipment: ITrackingShipment,
): ITrackingShipment => {
    const { items: trackingShipmentItems } = trackingShipment;
    const updatedTrackingShipmentItems = trackingShipmentItems.map((trackingShipmentItem: ITrackingShipmentItem) => {
        const { order_item: trackingOrderItem } = trackingShipmentItem;
        const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithParentBundle(parentOrderItems, trackingOrderItem);
        return {
            ...trackingShipmentItem,
            order_item: updatedOrderItem,
        };
    });

    return {
        ...trackingShipment,
        items: updatedTrackingShipmentItems,
    };
};

export const updateTrackingShipmentOrderItemsWithBundles = (
    shipmentTracking: IShipmentTracking,
): IShipmentTracking => {
    const { trackingShipment, trackingOrder } = shipmentTracking;
    const orderItems: IOrderItem[] = trackingOrder.items;
    const parentItems: IOrderItem[] = getParentOrderItems(orderItems);
    const updatedTrackingShipment: ITrackingShipment = updateReturnOrderItemsWithBundleItem(
        parentItems,
        trackingShipment,
    );
    return {
        ...shipmentTracking,
        trackingShipment: updatedTrackingShipment,
    };
};
