import React, { useRef, useState, useEffect } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { useClickOutside } from '@react-hooks-hub/use-click-outside';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import arrowDown from 'assets/images/down_arrow.svg';
import arrowUp from 'assets/images/up_arrow.svg';
import styles from './style.css';

interface ILinkPopupProps {
    linkLabel: string;
    beforeLabel?: string;
    showArrowAfterLink?: boolean;
    wrapperClassName?: string;
    linkClassName?: string;
    linkLabelClassName?: string;
    arrowClassName?: string;
    contentClassName?: string;
    linkTag?: string;
    dataTest?: string;
    isPopupOpen?: boolean;
    setIsPopupOpen?: (isPopupOpen: boolean) => void;
    /**
     * If `calculateTop` is allowed - position `relative` should not be set up on LinkPopup Wrapper!
     */
    calculateTop?: boolean;
    stopPropagation?: boolean;
    setWrapperHasZIndex?: (hasZIndex: boolean) => void;
    children?: React.ReactNode;
}

export const LinkPopup: React.FC<ILinkPopupProps> = (
    {
        linkLabel,
        beforeLabel,
        showArrowAfterLink = true,
        wrapperClassName,
        linkClassName,
        linkLabelClassName,
        arrowClassName,
        contentClassName,
        linkTag,
        dataTest,
        isPopupOpen,
        setIsPopupOpen,
        calculateTop = false,
        stopPropagation = false,
        setWrapperHasZIndex,
        children,
    },
) => {
    const [isOpen, setIsOpen] = useState(isPopupOpen || false);
    const [linkPopupTop, setLinkPopupTop] = useState(0);

    const t = usePhraseTranslater();
    const popupWrapperRef = useRef<HTMLDivElement>(null);

    const toggleContent = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        if (event && stopPropagation) event.stopPropagation();
        setIsOpen(!isOpen);
        if (setIsPopupOpen) setIsPopupOpen(!isOpen);
    };

    const handleClickOutside = (isOutside: boolean): void => {
        if (isOutside && isOpen) {
            setIsOpen(false);
            if (setIsPopupOpen) setIsPopupOpen(false);
        }
    };

    const getLinkPopupTopStyles = () => ({ top: `${linkPopupTop + 25}px` });

    const updateLinkPopupTop = (): void => {
        if (popupWrapperRef.current) {
            const { current } = popupWrapperRef;
            const { offsetTop } = current;
            setLinkPopupTop(offsetTop);
        }
    };

    useClickOutside([popupWrapperRef], handleClickOutside);

    useEffect(() => {
        updateLinkPopupTop();
        window.addEventListener('resize', updateLinkPopupTop);
        return () => window.removeEventListener('resize', updateLinkPopupTop);
    }, []);

    useEffect(() => {
        if (setWrapperHasZIndex) {
            setWrapperHasZIndex(isOpen);
        }
    }, [isOpen]);

    return (
        <div
            ref={popupWrapperRef}
            className={classes(styles.linkPopupWrapper, (wrapperClassName || ''), {
                [styles.positionRelative]: !calculateTop,
            })}
        >
            {beforeLabel && (
                <span className={styles.beforeLabel}>{beforeLabel}</span>
            )}
            <AccessibleButton
                tag={linkTag || 'button'}
                data-test={dataTest || 'link-popup-btn'}
                className={classes(styles.linkButton, (linkClassName || ''))}
                action={toggleContent}
            >
                <span className={classes(styles.linkLabel, (linkLabelClassName || ''))}>{linkLabel}</span>
                {showArrowAfterLink && (
                    <div className={classes(styles.arrow, (arrowClassName || ''))}>
                        <img
                            src={isOpen ? arrowUp : arrowDown}
                            alt={isOpen ? t('Content Open') : t('Content Closed')}
                        />
                    </div>
                )}
            </AccessibleButton>
            {isOpen && (
                <div
                    className={classes(styles.popupContentWrapper, (contentClassName || ''))}
                    style={calculateTop ? { ...getLinkPopupTopStyles() } : {}}
                >
                    {children}
                </div>
            )}
        </div>
    );
};
