import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    ITrackingAddress,
    ITrackingPackage,
} from 'graphql/sales/shipment/trackingInterfaces';
import { LinkPopup } from 'ui/component/custom/link-popup';
import { Activities } from '../activities';
import styles from '../../../style.css';

interface IPackageProps {
    trackingPackage: ITrackingPackage;
    orderStatus: string;
    packagesCount: number;
    currentPackageNumber: number;
}

export const Package: React.FC<IPackageProps> = (
    {
        trackingPackage,
        orderStatus,
        packagesCount,
        currentPackageNumber,
    },
) => {
    const t = usePhraseTranslater();
    const findAddressByType = (
        addresses: ITrackingAddress[],
        findByType: string,
    ): ITrackingAddress|undefined => addresses.find((address: ITrackingAddress) => (address.type === findByType));

    const getAddressLine = (address: ITrackingAddress): string => {
        const {
            region,
            city,
            country,
        } = address;

        let addressLine = '';

        if (city.length > 0) {
            addressLine += `${city}, `;
        }

        if (region.length > 0) {
            addressLine += `${region}, `;
        }

        if (country.length > 0) {
            addressLine += `${country}`;
        }

        return addressLine;
    };

    const {
        activity: trackingActivities,
        status,
        address: trackingAddresses,
        delivery,
    } = trackingPackage;

    const {
        description,
        shortDescription,
    } = status;

    const {
        location,
        receivedBy,
    } = delivery;

    const originAddress: ITrackingAddress|undefined = findAddressByType(trackingAddresses, 'origin');
    const destinationAddress: ITrackingAddress|undefined = findAddressByType(trackingAddresses, 'destination');

    const descriptionStatus = orderStatus !== 'Complete' ? t('Shipped') : t('Delivered');

    const mainStatus = description || descriptionStatus;
    const packageLabel = packagesCount > 1 ? ` (${t('Package %1', currentPackageNumber)})` : '';

    return (
        <div className={styles.trackingPackage}>
            <div className={classes(styles.packageRow, styles.packageHeader)}>
                <div className={styles.title}>
                    {`${mainStatus}${packageLabel}`}
                </div>
                {shortDescription && (
                    <div className={styles.underTitle}>
                        {shortDescription}
                    </div>
                )}
            </div>
            <div className={classes(styles.packageRow, styles.packageText)}>
                <div className={classes(styles.trackDetails, styles.addressAndTrack)}>
                    <div className={classes(styles.trackDetail, styles.addressPath)}>
                        <div className={styles.ship}>
                            {t('Ship')}
                        </div>
                        {originAddress && (
                            <>
                                <div className={styles.separator}>{t('from')}</div>
                                <div className={styles.addressFrom}>
                                    <strong>{getAddressLine(originAddress)}</strong>
                                </div>
                            </>
                        )}
                        {originAddress && destinationAddress && (
                            <div className={styles.separator}>{t('to')}</div>
                        )}
                        {destinationAddress && (
                            <div className={styles.addressTo}>
                                <strong>{getAddressLine(destinationAddress)}</strong>
                            </div>
                        )}
                    </div>
                    {trackingActivities.length > 0 && (
                        <LinkPopup
                            linkLabel={t('Track Details')}
                            wrapperClassName={classes(styles.trackDetail, styles.activitiesPopup)}
                            contentClassName={styles.activitiesPopupContent}
                        >
                            <Activities
                                activities={trackingActivities}
                            />
                        </LinkPopup>
                    )}
                </div>
                {location && (
                    <div className={styles.trackDetails}>
                        {t('Delivery location: %1', location)}
                    </div>
                )}
                {receivedBy && (
                    <div className={styles.trackDetails}>
                        {t('Delivery received by: %1', receivedBy)}
                    </div>
                )}
            </div>
        </div>
    );
};
