import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import {
    ITrackingActivity,
} from 'graphql/sales/shipment/trackingInterfaces';
import styles from '../../../../style.css';

interface IActivityProps {
    activity: ITrackingActivity;
}

export const Activity: React.FC<IActivityProps> = (
    {
        activity,
    },
) => {
    const {
        status,
        dateTime,
        city,
        country,
    } = activity;

    if (!status) {
        return null;
    }

    return (
        <div className={styles.activity}>
            <div className={classes(styles.activityLine, styles.first)}>
                <div className={styles.activityStatus}>
                    {status}
                </div>
                {city && (
                    <div className={styles.activityCity}>
                        <strong>{city}</strong>
                    </div>
                )}
                {country && (
                    <div className={styles.activityCountry}>
                        <strong>{country}</strong>
                    </div>
                )}
            </div>
            <div className={classes(styles.activityLine, styles.second)}>
                <div className={styles.activityDateTime}>
                    {dateTime}
                </div>
            </div>
        </div>
    );
};
