/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import {
    makeVar,
    ReactiveVar,
    useLazyQuery,
} from '@apollo/client';
import { getQueryParamByName } from 'ui/util/get-query-param-by-name';
import { IShipmentTracking } from 'graphql/sales/shipment/trackingInterfaces';
import { GET_SHIPMENT_TRACKING } from 'graphql/sales/shipment/tracking';
import { updateTrackingShipmentOrderItemsWithBundles } from 'graphql/sales/shipment/util/transform-tracking-orders';
import { useTrackingLoader } from './tracking-loader';

interface IInitializeTrackingProps {
    children: React.ReactNode;
}

export const trackingVar: ReactiveVar<IShipmentTracking|undefined> = makeVar<IShipmentTracking|undefined>(undefined);

const updateShipmentTracking = (tracking?: IShipmentTracking|undefined): void => {
    const shipmentTracking: IShipmentTracking|undefined = tracking
        ? updateTrackingShipmentOrderItemsWithBundles(tracking)
        : tracking;
    trackingVar(shipmentTracking);
};

export const resetStoredData = (): void => {
    updateShipmentTracking();
};

export const InitializeTracking: React.FC<IInitializeTrackingProps> = ({ children }) => {
    const { trackingLoading, setTrackingLoading } = useTrackingLoader();

    const hash = getQueryParamByName('hash');

    const [getShipmentTracking] = useLazyQuery(GET_SHIPMENT_TRACKING, {
        variables: {
            hash: '',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateShipmentTracking();
        },
        onCompleted: (data): void => {
            const { shipmentTracking } = data;
            updateShipmentTracking(shipmentTracking);
        },
    });

    const loadTrackingData = async (hash: string) => {
        await getShipmentTracking({
            variables: {
                hash,
            },
        });
    };

    useEffect(() => {
        resetStoredData();

        if (!hash) {
            return;
        }

        if (!trackingLoading) {
            setTrackingLoading(true);
        }

        loadTrackingData(hash).finally(() => {
            setTrackingLoading(false);
        });
    }, []);

    return (<>{children}</>);
};
