import React from 'react';
import { ITrackingShipmentItem } from 'graphql/sales/shipment/trackingInterfaces';
import { ProductImage } from 'ui/component/sales-components/product-image';
import trackingStyles from '../style.css';

interface IShipmentImagesProps {
    shipmentItems: ITrackingShipmentItem[];
}

export const ShipmentImages: React.FC<IShipmentImagesProps> = ({ shipmentItems }) => {
    if (!shipmentItems || !shipmentItems.length) {
        return null;
    }

    return (
        <>
            {shipmentItems.map((shipmentItem) => {
                const {
                    order_item: orderItem,
                } = shipmentItem;
                const {
                    product_name: productName,
                    product_image_url: productImageUrl,
                } = orderItem;
                return (
                    <ProductImage
                        productName={productName}
                        productImageUrl={productImageUrl}
                        className={trackingStyles.shipmentItemImage}
                        width={140}
                        height={160}
                    />
                );
            })}
        </>
    );
};
