import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { getMapsAddressLine } from 'graphql/customer/util/get-order-address-data';
import { IShipmentTracking } from 'graphql/sales/shipment/trackingInterfaces';
import {
    ISalesLayoutConfig,
    isLoggedInVar,
    countriesVar,
} from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { trackingVar } from 'ui/page/sales-pages/tracking-page/tracking-state';
import { Link } from 'ui/component/custom/link';
import { ICountry } from 'graphql/store/country';
import { Map } from './map';
import { TrackingInfoWindow } from './tracking-info-window';
import styles from './style.css';

interface ITrackingProps {
    config: ISalesLayoutConfig;
}

export const Tracking: React.FC<ITrackingProps> = ({ config }) => {
    const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);

    const t = usePhraseTranslater();

    const countries: ICountry[] = useReactiveVar(countriesVar);
    const isLoggedIn: boolean|null = useReactiveVar(isLoggedInVar);
    const shipmentTracking: IShipmentTracking|undefined = useReactiveVar(trackingVar);

    if (!shipmentTracking) {
        return null;
    }

    const { salesConfig } = config;
    const { googleMapsLibraryUrl } = salesConfig;

    const { trackingOrder } = shipmentTracking;
    const {
        status,
        order_urls: orderUrls,
        shipping_address: shippingAddress,
    } = trackingOrder;
    const { details_url: orderDetailsUrl } = orderUrls;

    return (
        <>
            <div className={classes(styles.trackingWrapper)}>
                <Map
                    googleMapsLibraryUrl={googleMapsLibraryUrl}
                    address={getMapsAddressLine(countries, shippingAddress)}
                    isInfoOpen={isInfoOpen}
                    setIsInfoOpen={setIsInfoOpen}
                    className={styles.trackingMap}
                >
                    <TrackingInfoWindow
                        config={config}
                        isLoggedIn={isLoggedIn}
                        shipmentTracking={shipmentTracking}
                        orderStatus={status}
                    />
                </Map>
                <div className={styles.orderInfo}>
                    <div className={styles.title}>
                        {t('Order Info')}
                    </div>
                    <div className={styles.orderUrl}>
                        <Link
                            href={orderDetailsUrl}
                            label={t('View order details')}
                            linkClassName={styles.trackingLink}
                            opacityOnHover
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
