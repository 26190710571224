import React, { useState, useEffect } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

interface IActionButtonProps {
    label: string;
    action: (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => void;
    btnClassName?: string;
    labelClassName?: string;
    tag?: string;
    show?: boolean;
    disabled?: boolean;
    dataTest?: string;
    childrenBeforeLabel?: boolean;
    children?: React.ReactNode;
}

export const ActionButton: React.FC<IActionButtonProps> = (
    {
        label,
        action,
        btnClassName,
        labelClassName,
        tag = 'button',
        show = true,
        disabled,
        dataTest,
        childrenBeforeLabel = true,
        children,
    },
) => {
    const [isDisabled, setIsDisabled] = useState(disabled || false);

    const executeAction = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => {
        if (action) {
            action(event);
        }
    };

    useEffect(() => {
        setIsDisabled(!!disabled);
    }, [disabled]);

    if (!show) {
        return null;
    }

    return (
        <>
            <AccessibleButton
                tag={tag}
                className={classes(styles.actionButton, (btnClassName || ''))}
                data-test={dataTest}
                disabled={isDisabled}
                tabIndex={0}
                action={executeAction}
            >
                {childrenBeforeLabel && (children)}
                <span className={classes(styles.actionTitle, (labelClassName || ''))}>{label}</span>
                {!childrenBeforeLabel && (children)}
            </AccessibleButton>
        </>
    );
};
