import React from 'react';
import { ITrackingPackage } from 'graphql/sales/shipment/trackingInterfaces';
import { Package } from './package';
import styles from '../../style.css';

interface IPackagesProps {
    packages: ITrackingPackage[];
    orderStatus: string;
}

export const Packages: React.FC<IPackagesProps> = (
    {
        packages,
        orderStatus,
    },
) => {
    const { length } = packages;
    return (
        <div className={styles.trackingPackages}>
            {packages.map(
                (trackingPackage: ITrackingPackage, index: number) => (
                    <Package
                        trackingPackage={trackingPackage}
                        orderStatus={orderStatus}
                        packagesCount={length}
                        currentPackageNumber={(index + 1)}
                    />
                ),
            )}
        </div>
    );
};
