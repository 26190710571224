import React, { createContext, useContext } from 'react';

interface ITrackingLoaderContext {
    trackingLoading: boolean;
    setTrackingLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<ITrackingLoaderContext | undefined>(undefined);

export const useTrackingLoader = (
): ITrackingLoaderContext => useContext(LoaderContext) as ITrackingLoaderContext;
