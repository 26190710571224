/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';
import {
    SHIPMENT_TRACKING_FIELDS,
    ORDER_ITEM_FIELDS,
    ORDER_ADDRESS_FIELDS,
    ORDER_URLS_FIELDS,
} from '../../customer/fragments';
import { IShipmentTrackingInput, IShipmentTrackingOutput } from './trackingInterfaces';

export const TRACKING_DETAILS_FIELDS = gql`
    fragment TrackingDetailsFields on TrackingPackage {
        activity {
            status
            dateTime
            city
            country
        }
        status {
            description
            shortDescription
        }
        address {
            type
            region
            city
            country
        }
        delivery {
            location
            receivedBy
        }
    }
`;

export const ORDER_SHIPMENT_FIELDS = gql`
    ${ORDER_ITEM_FIELDS}
    fragment OrderShipmentFields on OrderShipment {
        id
        number
        dateFormatted
        comments {
            timestamp
            message
        }
        items {
            id
            order_item {
                ...OrderItemFields,
            }
            product_sale_price {
                value
            }
            quantity_shipped
        }
    }
`;

export const ORDER_FIELDS = gql`
    ${ORDER_URLS_FIELDS}
    ${ORDER_ITEM_FIELDS}
    ${ORDER_ADDRESS_FIELDS}
    fragment OrderFields on CustomerOrder {
        id
        order_date
        order_date_formatted
        status
        number
        order_urls {
            ...OrderUrlsFields,
        }
        items {
            ...OrderItemFields,
        }
        shipping_address {
            ...OrderAddressFields,
        }
    }
`;

export const GET_SHIPMENT_TRACKING: TypedDocumentNode<IShipmentTrackingOutput, IShipmentTrackingInput> = gql`
    ${TRACKING_DETAILS_FIELDS}
    ${ORDER_SHIPMENT_FIELDS}
    ${SHIPMENT_TRACKING_FIELDS}
    ${ORDER_FIELDS}
    query GET_SHIPMENT_TRACKING(
        $hash: String!
    ) {
        shipmentTracking(
            hash: $hash
        ) {
            tracking {
                ...ShipmentTrackingFields,
                packages {
                    ...TrackingDetailsFields,
                }
            }
            trackingShipment {
                ...OrderShipmentFields,
            }
            trackingOrder {
                ...OrderFields,
            }
        }
    }
`;
